/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';

// slick css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// components
import VendorPartnerLogos from '../components/vendor-partner-logos';

// styled components
import ChalkboardStyles from '../components/styles/chalkboard-styles';
import EventsStyles from '../components/styles/events-styles';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';

// data
// import rsvpPdf from '../data/2020-IOLCF-Conference-RSVP.pdf';

// TODO: Query for these images for performance
// images
import Covid from '../images/covid.png';
import Vegas from '../images/vegas/vegas.jpg';
import VegasImage22013 from '../images/vegas/vegas13-2.jpg';
import VegasImage32013 from '../images/vegas/vegas13-3.jpg';
import VegasImage42013 from '../images/vegas/vegas13-4.jpg';
import VegasImage52013 from '../images/vegas/vegas13-5.jpg';
import VegasImage62013 from '../images/vegas/vegas13-6.jpg';

const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    useTransform: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const Events = () => (
    <Layout>
        <SEO title="Links of Interest" />
        <Title>Links of Interest</Title>
        <EventsStyles>
            <div className="Event__container">
                <div className="links__container">
                    <Title>Links of Interest</Title>
                    <div className="links__group">
                        <ul>
                            <li className="interest-link">
                                <a
                                    className="link"
                                    href="http://thecfainc.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Coalition of Franchisee Associations
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <li className="interest-link">
                                <a
                                    className="link"
                                    href="https://littlecaesars.com/en-us/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Little Caesars
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <li className="interest-link">
                                <a
                                    className="link"
                                    href="https://www.restaurant.org/Home"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    National Restaurant Association
                                </a>
                            </li>
                            <li className="interest-link">
                                <a
                                    className="link"
                                    href="https://www.pizzamarketplace.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Pizza Marketplace
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="webinar__container">
                    <Title>CFA Webinar Series</Title>
                    <div className="links__group">
                        <ul>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://images.magnetmail.net/images/clients/NFA_/attach/NASAAItem19Commentary.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Franchisors’ New Financial Performance
                                    Requirements: What You Need to Know
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/CFAWebinar_MillerLagarias.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    California’s New Franchisee Rights Law: How
                                    it Affects Your Business
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/CFAWebinar_Powell.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Finding and Keeping the Millennial Worker
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://thecfainc.com/action-center/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Voter Resources
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/CFAWebinar_DuerrWilson.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    The NLRB’s New Election Rules: Are You
                                    Prepared?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/CFAWebinar_Hays.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Health Care: What to Expect and How to
                                    Prepare
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/HowTheHealthCareLaw.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    How the Healthcare Law Affects the Franchise
                                    Community
                                </a>
                            </li>
                            <li>
                                <a
                                    className="webinar-link"
                                    href="http://www.thecfainc.com/wp-content/uploads/2016/04/DoesYourFranchiseAgreement.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Does Your Franchise Agreement Pass the Test?
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </EventsStyles>
        <VendorPartnerLogos />
    </Layout>
);

export default Events;
