import styled from 'styled-components';

const EventsStyles = styled.div`
  .Event__container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  ul {
    list-style: none; 
    padding: 0; 
    margin: 0;
  }

  li {
    padding: 20px;
  }

  .links__container {
    padding-top: 100px;
    padding-bottom: 100px;
    /* margin: 20px; */
  }

  .links__group {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;

    @media (max-width: 768px) {
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }

  .interest-link {
    margin: 20px;
  }

  .link {
    background-color: ${props => props.theme.orange};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.orange};
    color: ${props => props.theme.white};
    flex-grow: 1;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    width: 350px;
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.orange};
    }
  }

  .webinar__container {
    background-color: ${props => props.theme.gray};
    padding-top: 80px; 
    padding-bottom: 100px;
  }

  .webinar-link {
    font-size: 16px;
    line-height: 200%;
    color: ${props => props.theme.black};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.orange};
      cursor: pointer;
    }
  }

  .Slider__container {
    margin: 0 auto;
    padding: 0;
    width: 75%;
    display: flex;

    @media (max-width: 1024px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-dots {
    bottom: -20px;
  }

  .padding-top {
    margin-top: 40px;
    padding: 80px 0;
  }

`;

export default EventsStyles;
